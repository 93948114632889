import { Card, Row, Tabs } from "antd";
import React, { memo, useEffect, useState } from "react";
import Heading from "../../../component/common/Heading";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import RequestList from "./Request";
import History from "./History";
import useHttp from "../../../hooks/use-http";
// Update

function WithdrawalRequest({ admindetails, permissionId }) {
  const api = useHttp();
  const [totalReqAmonu, setTotalReqAmount] = useState(0);
  const [refreshAmount, setRefreshAmount] = useState(false);
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const menus = {
    bulkPe: (
      <RequestList
        accessPermission={accessPermission}
        setRefreshAmount={setRefreshAmount}
        transactionType={"bulkPe"}
      />
    ),
    giftCard: (
      <RequestList
        accessPermission={accessPermission}
        setRefreshAmount={setRefreshAmount}
        transactionType={"tremendous"}
      />
    ),
    history: <History accessPermission={accessPermission} />,
  };

  useEffect(() => {
    api.sendRequest(CONSTANTS.API.trajection.getPendingReqAmount, (res) => {
      setTotalReqAmount(res?.data?.totalPendingAmount);
    });
  }, [refreshAmount]);

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Withdrawal Request</Heading>
          <div className="text-base font-medium title-head mt-2 text-gray-400">
            Total Request Amount:{" "}
            <span className="text-red-500">₹ {totalReqAmonu}</span>
          </div>
          <Row>
            <Tabs
              className="p-0 mt-5"
              size="middle"
              type="card"
              items={CONSTANTS.TAB_MENU.WITHDRAWAL.map((ele) => {
                return {
                  label: `${ele.Label}`,
                  key: `${ele.id}`,
                  children: menus[ele?.id],
                };
              })}
            />
          </Row>
        </Card>
      </div>
    </>
  );
}

export default memo(WithdrawalRequest);
