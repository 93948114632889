import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { VscGraph } from "react-icons/vsc";
import { IoImageOutline, IoNotificationsOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { PERMISSION } from "./functions";
import RoleManagement from "../page/app/role-management";
import Banner from "../page/app/banner";
import Setting from "../page/app/setting";
import AccessForm from "../page/app/role-management/AccessForm";
import Brand from "../page/app/brand";
import BrandDetail from "../page/app/brand/Brand-Detail";
import BrandTaskDetail from "../page/app/brand/BrandTaskDetail";
import {
  IoIosNotificationsOutline,
  IoMdCheckmarkCircleOutline,
} from "react-icons/io";
import { LuListTodo } from "react-icons/lu";
import TaskDetail from "../page/app/task/TaskDetail";
import Task from "../page/app/task";
import UserDetail from "../page/app/user/UserDetail";
import { FaRegUser } from "react-icons/fa";
import User from "../page/app/user";
import TaskApproval from "../page/app/task-approval";
import Verification from "../page/app/verification";
import { FaRegAddressCard, FaLayerGroup } from "react-icons/fa6";
import {
  RiAdvertisementLine,
  RiCoupon2Line,
  RiMoneyRupeeCircleLine,
} from "react-icons/ri";
import WithdrawalRequest from "../page/app/withdrawal";
import Notification from "../page/app/notification";
import { MdDeviceUnknown } from "react-icons/md";
import UserDevice from "../page/app/user-device";
import Advertisement from "../page/app/Advertisement";
import GiftCards from "../page/app/gift-card";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "dashboard",
  USER_MANAGEMENT: "user",
  USER_MANAGEMENT_DETAIL: "user/:id",
  SETTING: "setting",
  BRAND_MANAGEMENT: "brand",
  BRAND_MANAGEMENT_DETAIL: "brand/:id",
  TASK_MANAGEMENT: "task",
  TASK_MANAGEMENT_DETAIL: "task/:id",
  TASK_APPROVAL: "approval",
  BANNER: "banner",
  ADVISEMENT: "advertisement",
  VERIFICATION: "verification",
  ROLE_MANAGEMENT: "role-management",
  ROLE_ACCESS_FORM: "access-form/:id",
  WITHDRAWAL: "withdrawal",
  NOTIFICATION: "notification",
  GIFTCARDS: "giftCard",
  DEVICE_MANAGEMENT: "device",
};

const LOGIN_ROUTES = [
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = (appProps) => {
  // const props = await appProps

  return [
    ...LOGIN_ROUTES,

    {
      path: `${appRoot}`,
      element: <AppLayout {...appProps} />,

      children: [
        {
          index: true,
          // element: <Navigate to={`${ROUTES.ROLE_MANAGEMENT}`} />,
          element: (
            <Navigate
              to={`${appRoot}/${
                appProps?.admindetails?.access?.split(",")[0]?.split("-")[0] ===
                  "admin" ||
                appProps?.admindetails?.access?.split(",")[0]?.split("-")[0] ===
                  "role"
                  ? ROUTES.ROLE_MANAGEMENT
                  : appProps?.admindetails?.access
                      ?.split(",")[0]
                      ?.split("-")[0] || ROUTES.BANNER
              }`}
            />
          ),
        },
        {
          icon: <VscGraph />,
          label: "Dashboard",
          id: ROUTES.DASHBOARD,
          path: `${appRoot}/${ROUTES.DASHBOARD}`,
          element: <Dashboard {...appProps} permissionId={ROUTES.DASHBOARD} />,
          Role: [
            `${ROUTES.DASHBOARD}-${PERMISSION.READ}`,
            `${ROUTES.DASHBOARD}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.DASHBOARD}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <FaRegUser />,
          label: "User Management",
          id: ROUTES.USER_MANAGEMENT,
          path: `${appRoot}/${ROUTES.USER_MANAGEMENT}`,
          element: <User {...appProps} permissionId={ROUTES.USER_MANAGEMENT} />,
          Role: [
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          path: `${appRoot}/${ROUTES.USER_MANAGEMENT_DETAIL}`,
          element: (
            <UserDetail {...appProps} permissionId={ROUTES.USER_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.USER_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <FaLayerGroup />,
          label: "Brand Management",
          id: ROUTES.BRAND_MANAGEMENT,
          path: `${appRoot}/${ROUTES.BRAND_MANAGEMENT}`,
          element: (
            <Brand {...appProps} permissionId={ROUTES.BRAND_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          path: `${appRoot}/${ROUTES.BRAND_MANAGEMENT_DETAIL}`,
          element: (
            <BrandDetail {...appProps} permissionId={ROUTES.BRAND_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          path: `${appRoot}/${ROUTES.BRAND_MANAGEMENT_DETAIL}/${ROUTES.TASK_MANAGEMENT_DETAIL}`,
          element: (
            <BrandTaskDetail
              {...appProps}
              permissionId={ROUTES.BRAND_MANAGEMENT}
            />
          ),
          Role: [
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.BRAND_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <LuListTodo />,
          label: "Task Management",
          id: ROUTES.TASK_MANAGEMENT,
          path: `${appRoot}/${ROUTES.TASK_MANAGEMENT}`,
          element: <Task {...appProps} permissionId={ROUTES.TASK_MANAGEMENT} />,
          Role: [
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          path: `${appRoot}/${ROUTES.TASK_MANAGEMENT_DETAIL}`,
          element: (
            <TaskDetail {...appProps} permissionId={ROUTES.TASK_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.TASK_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <IoMdCheckmarkCircleOutline />,
          label: "Task Approval",
          id: ROUTES.TASK_APPROVAL,
          path: `${appRoot}/${ROUTES.TASK_APPROVAL}`,
          element: (
            <TaskApproval {...appProps} permissionId={ROUTES.TASK_APPROVAL} />
          ),
          Role: [
            `${ROUTES.TASK_APPROVAL}-${PERMISSION.READ}`,
            `${ROUTES.TASK_APPROVAL}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.TASK_APPROVAL}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <FaRegAddressCard />,
          label: "User Verification",
          id: ROUTES.VERIFICATION,
          path: `${appRoot}/${ROUTES.VERIFICATION}`,
          element: (
            <Verification {...appProps} permissionId={ROUTES.VERIFICATION} />
          ),
          Role: [
            `${ROUTES.VERIFICATION}-${PERMISSION.READ}`,
            `${ROUTES.VERIFICATION}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.VERIFICATION}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <RiMoneyRupeeCircleLine />,
          label: "Withdrawal Request",
          id: ROUTES.WITHDRAWAL,
          path: `${appRoot}/${ROUTES.WITHDRAWAL}`,
          element: (
            <WithdrawalRequest {...appProps} permissionId={ROUTES.WITHDRAWAL} />
          ),
          Role: [
            `${ROUTES.WITHDRAWAL}-${PERMISSION.READ}`,
            `${ROUTES.WITHDRAWAL}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.WITHDRAWAL}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <IoImageOutline />,
          label: "Banner",
          id: ROUTES.BANNER,
          path: `${appRoot}/${ROUTES.BANNER}`,
          element: <Banner {...appProps} permissionId={ROUTES.BANNER} />,
          Role: [
            `${ROUTES.BANNER}-${PERMISSION.READ}`,
            `${ROUTES.BANNER}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.BANNER}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <RiAdvertisementLine />,
          label: "Advertisement",
          id: ROUTES.ADVISEMENT,
          path: `${appRoot}/${ROUTES.ADVISEMENT}`,
          element: (
            <Advertisement {...appProps} permissionId={ROUTES.ADVISEMENT} />
          ),
          Role: [
            `${ROUTES.ADVISEMENT}-${PERMISSION.READ}`,
            `${ROUTES.ADVISEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.ADVISEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <HiOutlineUserGroup />,
          label: "Role & Access",
          id: ROUTES.ROLE_MANAGEMENT,
          path: `${appRoot}/${ROUTES.ROLE_MANAGEMENT}`,
          element: (
            <RoleManagement
              {...appProps}
              permissionId={ROUTES.ROLE_MANAGEMENT}
            />
          ),
          Role: [
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.ALL}`,
            `admin-${PERMISSION.READ}`,
            `admin-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `admin-${PERMISSION.ALL}`,
          ],
        },
        {
          // id: ROUTES.ROLE_ACCESS_FORM,
          path: `${appRoot}/${ROUTES.ROLE_MANAGEMENT}/${ROUTES.ROLE_ACCESS_FORM}`,
          element: (
            <AccessForm {...appProps} permissionId={ROUTES.ROLE_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.ROLE_MANAGEMENT}-${PERMISSION.ALL}`,
            `admin-${PERMISSION.READ}`,
            `admin-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `admin-${PERMISSION.ALL}`,
          ],
        },

        {
          icon: <MdDeviceUnknown />,
          label: "Device Management",
          id: ROUTES.DEVICE_MANAGEMENT,
          path: `${appRoot}/${ROUTES.DEVICE_MANAGEMENT}`,
          element: (
            <UserDevice {...appProps} permissionId={ROUTES.DEVICE_MANAGEMENT} />
          ),
          Role: [
            `${ROUTES.DEVICE_MANAGEMENT}-${PERMISSION.READ}`,
            `${ROUTES.DEVICE_MANAGEMENT}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.DEVICE_MANAGEMENT}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <IoNotificationsOutline />,
          label: "Notification",
          id: ROUTES.NOTIFICATION,
          path: `${appRoot}/${ROUTES.NOTIFICATION}`,
          element: (
            <Notification {...appProps} permissionId={ROUTES.NOTIFICATION} />
          ),
          Role: [
            `${ROUTES.NOTIFICATION}-${PERMISSION.READ}`,
            `${ROUTES.NOTIFICATION}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.NOTIFICATION}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <RiCoupon2Line />,
          label: "Gift Cards",
          id: ROUTES.GIFTCARDS,
          path: `${appRoot}/${ROUTES.GIFTCARDS}`,
          element: <GiftCards {...appProps} permissionId={ROUTES.GIFTCARDS} />,
          Role: [
            `${ROUTES.GIFTCARDS}-${PERMISSION.READ}`,
            `${ROUTES.GIFTCARDS}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.GIFTCARDS}-${PERMISSION.ALL}`,
          ],
        },
        {
          icon: <IoSettingsOutline />,
          label: "Setting",
          id: ROUTES.SETTING,
          path: `${appRoot}/${ROUTES.SETTING}`,
          element: <Setting {...appProps} permissionId={ROUTES.SETTING} />,
          Role: [
            `${ROUTES.SETTING}-${PERMISSION.READ}`,
            `${ROUTES.SETTING}-${PERMISSION.WRITE_WITH_OUT_DELETE}`,
            `${ROUTES.SETTING}-${PERMISSION.ALL}`,
          ],
        },
      ],
    },
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "/unAuthorize",
      element: <UnAuthorize />,
    },
    {
      path: "*",
      element: <Navigate to="/error" />,
    },
  ];
};

export default ALL_ROUTES;
