import { Row, Col, Card, Image } from "antd";

const ProductDetails = ({ data }) => {
  return (
    <div key={data?.id} className="mb-6">
      <Row gutter={[12, 24]}>
        <Col span={8}>
          <Image
            height={86}
            width={172}
            style={{
              aspectRatio: "172/86",
              objectFit: "cover",
              borderRadius: 8,
            }}
            src={data?.images?.[0]?.src}
            alt={data?.name}
          />
        </Col>
        {/* Details split into 3 columns */}
        <Col span={16}>
          <Row>
            <p className="text-gray-400 mb-2">
              Currency:{" "}
              <span className="text-black">
                {data?.currency_codes?.join(", ")}
              </span>
            </p>
          </Row>
          <Row>
            {data?.skus?.length <= 1 ? (
              <>
                <Col span={8}>
                  <p className="text-gray-400 mb-2">
                    Min Amount:{" "}
                    <span className="text-black">
                      {data?.skus[0]?.min || "N/A"}
                    </span>
                  </p>
                </Col>
                <Col span={8}>
                  <p className="text-gray-400 mb-2">
                    Max Amount:{" "}
                    <span className="text-black">
                      {data?.skus[0]?.max || "N/A"}
                    </span>
                  </p>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <p className="text-gray-400 mb-2">
                  Card Amounts:{" "}
                  <span className="text-black">
                    {data?.skus?.map((sku) => sku?.min)?.join(", ")}
                  </span>
                </p>
              </Col>
            )}
          </Row>
        </Col>

        {data?.description && (
          <Col span={24}>
            <p className="text-gray-400 mb-2">Description:</p>
            <Card className="max-h-52 overflow-y-auto py-1 px-1">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.description.replace(/\r\n/g, "<br />"),
                }}
              />
            </Card>
          </Col>
        )}
        <Col span={24}>
          <p className="text-gray-400 mb-2">Disclosure:</p>
          <Card className="max-h-72 overflow-y-auto py-1 px-1">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.disclosure.replace(/\r\n/g, "<br />"),
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetails;
