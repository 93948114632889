import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import { Button, Card, Modal, Popconfirm, Row } from "antd";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import ProductDetails from "./ViewCardDetails";

const TremendousGiftCard = ({ compoId }) => {
  const api = useHttp();
  const [modalData, setModalData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (compoId === "tremendousGiftCard") setRefresh((pre) => !pre);
  }, [compoId]);

  const formatSkus = (skus) => {
    if (skus?.length === 1)
      return `Min ${skus?.[0]?.min} to Max ${skus?.[0]?.max}`;

    const amounts = skus?.map((sku) => sku.min);
    return amounts?.join(", ");
  };

  const handleAddInList = (productId) => {
    if (productId)
      api.sendRequest(
        CONSTANTS.API.GiftCards.add,
        () => {
          setRefresh((prev) => !prev);
        },
        { productId },
        `Add Product Successfully`
      );
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Row>
            <CRUDComponent
              title="Gift Card"
              reload={refresh}
              showPagination={false}
              GET={{
                API: CONSTANTS.API.GiftCards.getAllProduct,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      cardValue: formatSkus(data?.skus),
                      cardDetails: {
                        id: "cardDetails",
                        onClick: () => {
                          if (data?.id) setModalData(data);
                        },
                      },
                      rankingNumber: data?.ranking,
                      image: data?.images?.[0]?.src,
                      onAdd: (
                        <Popconfirm
                          onConfirm={() => handleAddInList(data?.id)}
                          title="Are You Sure?"
                        >
                          <Button type="primary" loading={api?.isLoading}>
                            Add
                          </Button>
                        </Popconfirm>
                      ),
                    };
                  });
                },
                column: CONSTANTS.TABLE.GIFTCARDLIST,
              }}
              isSearch
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={modalData?.name}
        centered
        open={modalData}
        onCancel={() => {
          setModalData(null);
        }}
        width={700}
        footer={null}
      >
        <ProductDetails data={modalData} />
      </Modal>
    </>
  );
};

export default TremendousGiftCard;
