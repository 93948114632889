import React, { useEffect, useState } from "react";
import RichTextForm from "../../../../component/common/RichTextForm";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator, PERMISSION } from "../../../../util/functions";
import useHttp from "../../../../hooks/use-http";

const TaskTermAndCondition = ({ accessPermission }) => {
  const [data, setData] = useState(null);
  const api = useHttp();
  useEffect(() => {
    const GET_SPIN_TERMS_API = apiGenerator(CONSTANTS.API.setting.terms.getAll);
    api.sendRequest(
      GET_SPIN_TERMS_API,
      (res) => {
        setData(res?.data?.rows?.[0]);
      },
      { type: "taskTermAndCondition" }
    );
  }, []);
  return (
    <>
      <RichTextForm
        Title="Work Agreement"
        BaseData={{
          information: data?.information,
        }}
        EndpointObject={apiGenerator(CONSTANTS.API.setting.terms.update, {
          dataId: data?.id,
        })}
        menuFields={[
          {
            id: "information",
            type: "richTextarea",
            placeholder: "Type here...",
            Label: "",
            validationMessage: "Please Enter about",
            name: "information",
            required: true,
          },
        ]}
        successMessage="Terms and Condition Updated Successfully"
        disabled={PERMISSION[accessPermission] === "READ"}
      />
    </>
  );
};

export default TaskTermAndCondition;
