import React, { memo, useState } from "react";
import { Card, Row, Tabs } from "antd";
import Heading from "../../../component/common/Heading";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import GiftCard from "./GiftCard";
import TremendousGiftCard from "./TremendousGiftCard";

function GiftCards({ admindetails, permissionId }) {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const [tab, setTab] = useState("");

  const menus = {
    giftCard: <GiftCard compoId={tab} accessPermission={accessPermission} />,
    tremendousGiftCard: <TremendousGiftCard compoId={tab}/>,
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Gift Cards</Heading>
          <Row>
            <Tabs
              className="p-0 mt-5"
              size="middle"
              type="card"
              onChange={(activeKey) => setTab(activeKey)}
              items={CONSTANTS.TAB_MENU.GIFTCARD.map((ele) => {
                return {
                  label: `${ele.Label}`,
                  key: `${ele.id}`,
                  children: menus[ele?.id],
                };
              })}
            />
          </Row>
        </Card>
      </div>
    </>
  );
}

export default memo(GiftCards);
