import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import { Card, Modal, Row } from "antd";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator, PERMISSION } from "../../../util/functions";
import ProductDetails from "./ViewCardDetails";

const GiftCard = ({ compoId, accessPermission }) => {
  const api = useHttp();
  const [modalData, setModalData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (compoId === "giftCard") setRefresh((pre) => !pre);
  }, [compoId]);

  return (
    <>
      <div>
        <Card className="my-5">
          <Row>
            <CRUDComponent
              reload={refresh}
              title="Gift Card"
              GET={{
                API: CONSTANTS.API.GiftCards.getAll,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      ranking: {
                        data: data?.ranking,
                        onBlur: (Rank) => {
                          if (!isNaN(Number(Rank))) {
                            const UpdateAPIEnd = {
                              ...CONSTANTS?.API.GiftCards.update,
                            };
                            UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${data?.id}`;
                            API.sendRequest(
                              UpdateAPIEnd,
                              () => {
                                Setrefresh((prev) => !prev);
                              },
                              { ranking: Number(Rank) },
                              `Ranking Update Successfully`
                            );
                          }
                        },
                      },
                      cardDetails: {
                        id: "cardDetails",
                        onClick: () => {
                          if (!data?.id) return;
                          const GET_TASK_API = apiGenerator(
                            CONSTANTS.API.GiftCards.getOne,
                            {
                              id: data?.id,
                            }
                          );
                          api.sendRequest(GET_TASK_API, (res) => {
                            if (!res) return;
                            console.log(res);
                            setModalData(res?.productData?.product);
                          });
                        },
                      },
                      rankingNumber: data?.ranking,
                      image: data?.logo,
                      isActive: {
                        checked: !data?.isActive,
                        id: data?.id,
                        isDisable: PERMISSION[accessPermission] === "READ",
                        onClick: (id, val) => {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.GiftCards.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { isActive: val },
                            `Gift Card ${
                              val ? "Active" : "Inactive"
                            } Successfully`
                          );
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.GIFTCARD,
              }}
              isSearch
              DELETE={{
                API: CONSTANTS.API.GiftCards.delete,
                message: "Gift Card Deleted Successfully",
              }}
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={modalData?.name}
        centered
        open={modalData}
        onCancel={() => {
          setModalData(null);
        }}
        width={700}
        footer={null}
      >
        <ProductDetails data={modalData} />
      </Modal>
    </>
  );
};

export default GiftCard;
